import Box from '@mui/material/Box'
import Image from 'next/image'
import React, { useState } from 'react'
import FlexBox from 'src/components/FlexBox'
import BaseChip from 'src/components/Inputs/BaseChip'
import ModalDrawer from 'src/components/ModalDrawer'
import { SizeFilter } from 'src/types/graphql'
import LeftArrow from '../../../../public/svgs/Left.svg'
import RightArrow from '../../../../public/svgs/Right.svg'
import { Colors, FontWeight } from '../../../constants/theme'
import { RedirectUrls } from '../../../types/types'
import BaseButton from '../../Buttons/BaseButton'
import {
  StyledDots,
  StyledModalDescription,
  StyledModalOptions,
  StyledSizeImageContainer
} from './styled'

type ImageOptions = 'items' | 'vehicles'

enum SizeGuideFilter {
  Any = 'ANY',
  S1x1 = 's1x1',
  S1x3 = 'x1x3',
  S3x3 = 's3x3',
  S3x6 = 's3x6',
  S6x6 = 's6x6',
  S6x9 = 's6x9'
}

const SIZE_MAP: { [key in SizeGuideFilter]: SizeFilter } = {
  [SizeGuideFilter.Any]: SizeFilter.Any,
  [SizeGuideFilter.S1x1]: SizeFilter.Small,
  [SizeGuideFilter.S1x3]: SizeFilter.Small,
  [SizeGuideFilter.S3x3]: SizeFilter.Medium,
  [SizeGuideFilter.S3x6]: SizeFilter.Medium,
  [SizeGuideFilter.S6x6]: SizeFilter.Large,
  [SizeGuideFilter.S6x9]: SizeFilter.Large
}

const SIZES_MAP: { [key in string]: string } = {
  [SizeGuideFilter.S1x1]: '1.5 x 1.5',
  [SizeGuideFilter.S1x3]: '1.5 x 3',
  [SizeGuideFilter.S3x3]: '3 x 3',
  [SizeGuideFilter.S3x6]: '3 x 6',
  [SizeGuideFilter.S6x6]: '6 x 6',
  [SizeGuideFilter.S6x9]: '6 x 9'
}

const OPTIONS: {
  [key in string]: {
    label: string
    img: { items: string; vehicles: string }
  }
} = {
  [SizeGuideFilter.Any]: {
    label: '',
    img: { items: '', vehicles: '' }
  },
  [SizeGuideFilter.S1x1]: {
    label: 'Chico',
    img: {
      items: '/svgs/items-1x5.svg',
      vehicles: ''
    }
  },
  [SizeGuideFilter.S1x3]: {
    label: 'Chico',
    img: {
      items: '/svgs/items-1.5x3.svg',
      vehicles: ''
    }
  },
  [SizeGuideFilter.S3x3]: {
    label: 'Mediano',
    img: {
      items: '/svgs/items-3x3.svg',
      vehicles: ''
    }
  },
  [SizeGuideFilter.S3x6]: {
    label: 'Mediano',
    img: {
      items: '/svgs/items-3x6.svg',
      vehicles: '/svgs/vehicles-3x6.svg'
    }
  },
  [SizeGuideFilter.S6x6]: {
    label: 'Grande',
    img: {
      items: '/svgs/items-6x6.svg',
      vehicles: '/svgs/vehicles-6x6.svg'
    }
  },
  [SizeGuideFilter.S6x9]: {
    label: 'Grande',
    img: {
      items: '/svgs/items-6x9.svg',
      vehicles: '/svgs/vehicles-6x9.svg'
    }
  }
}

type IProps = {
  open: boolean
  handleClose: (open: boolean) => void
}

const StorageSizeModal = ({ open, handleClose }: IProps) => {
  const [selectedOption, setSelectedOption] = useState<SizeGuideFilter>(
    SizeGuideFilter.S3x6
  )
  const [selectedLabel, setSelectedLabel] = useState(0)
  const [selectedImg, setSelectedImg] = useState<ImageOptions>('items')
  const ITEM = [
    { label: 'Objetos' },
    ![
      SizeGuideFilter.S1x1,
      SizeGuideFilter.S1x3,
      SizeGuideFilter.S3x3
    ].includes(selectedOption)
      ? { label: 'Vehículo' }
      : null
  ].filter(Boolean)

  const selectedOptionData = OPTIONS[selectedOption] || {}
  const { img } = selectedOptionData

  const values = Object.values(SizeGuideFilter)
  const optionValues = values.filter((option) => option !== SizeGuideFilter.Any)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentIndex = optionValues.indexOf(selectedOption as any)

  const selectPreviousOption = () => {
    if (currentIndex > 0) {
      const previousOption = optionValues[currentIndex - 1]
      setSelectedOption(previousOption as SizeGuideFilter)
      setSelectedImg('items')
      setSelectedLabel(0)
    }
  }

  const selectNextOption = () => {
    if (currentIndex < optionValues.length - 1) {
      const nextOption = optionValues[currentIndex + 1]
      setSelectedOption(nextOption as SizeGuideFilter)
      setSelectedImg('items')
      setSelectedLabel(0)
    }
  }

  return (
    <ModalDrawer
      isOpen={open}
      setIsOpen={handleClose}
      title="Guía de tamaños"
      subtitle="Tamaños de almacenamiento comunes (m²):"
      sx={{ width: '50%' }}
    >
      <FlexBox column fullWidth alignCenter justifyCenter>
        <BaseChip
          sx={{ py: '0 !important' }}
          horizontal
          entries={SIZES_MAP}
          value={selectedOption}
          onSelectValue={(val) => setSelectedOption(val as SizeGuideFilter)}
        />

        <StyledSizeImageContainer>
          <div style={{ cursor: 'pointer' }} onClick={selectPreviousOption}>
            <Image src={LeftArrow} alt="left-arrow" />
          </div>
          <Box maxHeight={290}>
            <Image
              src={img[selectedImg]}
              alt="size-image"
              width="300"
              height="300"
              quality={100}
            />
          </Box>
          <div style={{ cursor: 'pointer' }} onClick={selectNextOption}>
            <Image src={RightArrow} alt="right-arrow" />
          </div>
        </StyledSizeImageContainer>
        <StyledModalOptions>
          {ITEM.map((item, index) => (
            <BaseButton
              key={index}
              rounded
              variant="outlined"
              customColor={
                index === selectedLabel ? Colors.blue500 : 'transparent'
              }
              customContrastColor={
                index === selectedLabel ? Colors.blue500 : Colors.black300
              }
              bold
              fontWeight={FontWeight.semiBold}
              onClick={() => {
                setSelectedImg(index === 0 ? 'items' : 'vehicles'),
                  setSelectedLabel(index)
              }}
              sx={{
                fontSize: '12px !important',
                height: '36px !important',
                '&:hover': {
                  filter: 'none !important'
                },
                width: { md: 129, sx: 95 }
              }}
            >
              {item?.label}
            </BaseButton>
          ))}
        </StyledModalOptions>

        <StyledDots>
          {optionValues.map((item, index) => (
            <Box
              id={item}
              key={index}
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                margin: '0 4px',
                background:
                  index == currentIndex ? Colors.blue500 : Colors.grey300,
                cursor: 'pointer'
              }}
              onClick={() => setSelectedOption(item)}
            />
          ))}
        </StyledDots>

        <StyledModalDescription id="modal-modal-description">
          Estas son solo aproximaciones y las imágenes son meramente
          ilustrativas. La capacidad del espacio que solicites puede variar
          ligeramente.
        </StyledModalDescription>
        <BaseButton
          rounded
          variant="contained"
          color="primary"
          customColor={Colors.link}
          fontSize={14}
          fontWeight={FontWeight.medium}
          href={`${RedirectUrls.Search}?size=${SIZE_MAP[selectedOption]}`}
        >
          Buscar este tamaño
        </BaseButton>
      </FlexBox>
    </ModalDrawer>
  )
}

export default React.memo(StorageSizeModal)
